import { getData } from './script_data.js';
import FontLoader from '../tk/font_loader.js';

/**
 * Make sure the Dynamic Kit configuration is setup before anything else.
 */
export function init() {
  if (!window.Typekit) {
    return;
  }

  const config = getData('dynamic-kit-config');
  if (!config) {
    return;
  }

  window.Typekit.user = config.user;
  window.Typekit.token = config.token;
}

export function loadFonts() {
  if (!window.Typekit) {
    return;
  }

  window.Typekit.load();

  const loader = new FontLoader();
  const fonts = getData('dynamic-kit-fonts');
  if (!fonts) {
    return;
  }

  fonts.forEach(font => {
    loader.loadFont(font.css_name, font.opaque_id, font.fvd, font.unicode_range, font.feature_settings, font.dynamic);
  });
}
