// FontLoader is DEPRECATED. Please use FontLoaderService.

// FontLoader provides a simpler API and callback behavior when loading many
// fonts over time. It expects that each font is loaded in a family with only
// one font ("family-name-n4") using the preview API, which requires an css
// name, alias (opaque id), fvd and subset generated by the server with access
// only to that font.
//
var _ = require('underscore');

const FontLoader = function() {
  this._loadedFonts = {};
  this._activeFonts = {};
  this._fontsToLoad = [];
  this._callbacks = {};

  // Public: Set the maximum number of concurrent requests.
  this.maxConcurrentRequests = 16;

  // Hash to track which request id made the request for a font.
  this._requestMap = {};
  // Array of request ids that are currently being made.
  this._currentRequests = [];
  // Whether font loading is paused or unpaused.
  this._paused = false;
  // Timeout for async loading of fonts to load.
  this._loadFontsToLoadTimeout = null;
};

// Public: Pause or unpause the loading of fonts. Any font requests that haven't
// already been kicked off will be postponed until the font loader is unpaused
// again.
FontLoader.prototype.paused = function(value) {
  this._paused = value;
  if (this._paused) {
    clearTimeout(this._loadFontsToLoadTimeout);
  } else {
    this._loadFontsToLoadTimeout = setTimeout(this._loadFontsToLoad.bind(this));
  }
};

// Public: Load a single font in its own family via the internal API with a
// hmac, description, and token generated by the server.
//
// cssName          - String name that the family will be loaded with.
// alias            - String alias for the font (opaque id).
// fvd              - String font variation description.
// unicodeRange     - String unicode range (CSS syntax).
// featureSettings  - String OpenType features (CSS syntax).
// dynamic          - Boolean true if the font is dynamic.
// opt_fontCallback - Function to call when the font loads (default: no callback).
//
// Returns nothing.
FontLoader.prototype.loadFont = function(cssName, alias, fvd, unicodeRange, featureSettings, dynamic, opt_fontCallback) {
  // If there is not dynamic kit call the callback right away and return unsuccessfully.
  if (!window.Typekit.Font) {
    opt_fontCallback(false);
    return;
  }

  // Decide whether each font is loaded or not. If it is, we'll execute the callback
  // immediately. If not, we'll queue it up to load.
  if (this._loadedFonts[cssName]) {
    if (this._activeFonts[cssName]) {
      if (opt_fontCallback) {
        opt_fontCallback(true);
      }
    } else {
      if (opt_fontCallback) {
        this._addCallback(cssName, opt_fontCallback);
      }
    }
    return;
  }

  // Time how long it takes to load a font from the original loadFont call.
  this._loadedFonts[cssName] = +new Date;

  // Store callback to call.
  if (opt_fontCallback) {
    this._addCallback(cssName, opt_fontCallback);
  }

  // Add the font to the queue of things to be loaded.
  this._fontsToLoad.push({
    cssName: cssName,
    alias: alias,
    fvd: fvd,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings,
    dynamic: dynamic
  });

  // Asynchronously kick off loading of fonts, so that we have a chance to
  // pause font loading before requests are actually made.
  if (!this._paused) {
    clearTimeout(this._loadFontsToLoadTimeout);
    this._loadFontsToLoadTimeout = setTimeout(this._loadFontsToLoad.bind(this));
  }
};

// If the font loader is unpaused, load fonts from the array of fonts to load
// until we reach the maximum number of concurrent requests. Otherwise, do
// nothing.
//
// Returns nothing.
FontLoader.prototype._loadFontsToLoad = function() {
  if (this._paused) return;
  while(this._fontsToLoad.length > 0 && this._currentRequests.length < this.maxConcurrentRequests) {
    var font = this._fontsToLoad.shift();
    this._loadFont(font.cssName, font.alias, font.fvd, font.unicodeRange, font.featureSettings, font.dynamic);
  }
};

// Private: Kick off a new request for a font using the dynamic kit API.
//
// cssName         - String name that the family will be loaded with.
// alias           - String alias for the font (opaque id).
// fvd             - String font variation description.
// unicodeRange    - String unicode range (CSS syntax).
// featureSettings - String feature settings (CSS syntax).
// dynamic         - Boolean true if the font is dynamic.
//
// Returns nothing
FontLoader.prototype._loadFont = function(cssName, alias, fvd, unicodeRange, featureSettings, dynamic) {
  var self = this;

  // Add a new request.
  var requestId = _.uniqueId() + 1; // avoid 0 is false case.
  this._currentRequests.push(requestId);

  // Remember which request this font was loaded in.
  this._requestMap[cssName] = requestId;

  Typekit.user = "tk";
  Typekit.token = "UWiewzNGqfaXgl50sfTX597VqiNVO+VsJvzyIM5SabTjZinSwqaidRKypEX3LMfHdBUibNmD+PCRx/5yCXr4NA==";

  var weight = fvd[1] + '00';
  var style = (fvd[0] === 'n' ? 'normal' : (fvd[0] === 'i' ? 'italic' : 'oblique'));

  var font = new Typekit.Font(cssName, alias, {
    weight: weight,
    style: style,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings,
    dynamic: dynamic
  });

  font.load().then(function () {
    Typekit.fonts.add(font);
    self.fontActive_(cssName, fvd);
  }, function () {
    self.fontInactive_(cssName, fvd);
  });
};

// Handle WebFontLoader fontactive. Detect that a font has loaded and execute
// the callback for that particular font.
FontLoader.prototype.fontActive_ = function(cssName, fvd) {
  var requestId = this._handleRequestReturned(cssName);

  var now = +new Date;
  var loadedAt = this._loadedFonts[cssName];
  this._activeFonts[cssName] = now;

  var callbacks = this._callbacks[cssName];
  delete this._callbacks[cssName];

  if (global.tk.GlobalFontEvents) {
    global.tk.GlobalFontEvents.fontActive(cssName, fvd);
  }

  _.each(callbacks, function(callback) { callback(true); });
};

// Handle WebFontLoader fontinactive. Detect that a font has loaded and execute
// the callback for that particular font.
FontLoader.prototype.fontInactive_ = function(cssName, fvd) {
  var requestId = this._handleRequestReturned(cssName);

  delete this._loadedFonts[cssName];
  delete this._activeFonts[cssName];

  var callbacks = this._callbacks[cssName];
  delete this._callbacks[cssName];

  if (global.tk.GlobalFontEvents) {
    global.tk.GlobalFontEvents.fontInactive(cssName, fvd);
  }

  _.each(callbacks, function(callback) { callback(false); });
};

// Handle that font was loaded. This cleans up the status of outstanding requests
// and kicks off any pending batches.
//
// Returns the requestId if it was pending, else null.
FontLoader.prototype._handleRequestReturned = function(cssName) {
  var requestWasPending = false;

  // Find out which batch loaded this font.
  var requestId = this._requestMap[cssName];

  if (requestId) {
    delete this._requestMap[cssName];
    requestWasPending = _.include(this._currentRequests, requestId);
    if (requestWasPending) {
      this._currentRequests = _.without(this._currentRequests, requestId);
    }
  }

  // Kick off any pending font requests if any are available.
  this._loadFontsToLoad();

  return requestWasPending ? requestId : null;
};

// Add a callback for a given CSS name for a font to the array of callbacks.
//
// cssName      - String name that the font family will be loaded with.
// fontCallback - Function to call when the font loads.
//
// Returns nothing.
FontLoader.prototype._addCallback = function(cssName, fontCallback) {
  if (!this._callbacks[cssName]) this._callbacks[cssName] = [];
  this._callbacks[cssName].push(fontCallback);
};

module.exports = FontLoader;
