import { getData } from './script_data.js';
import ImsAuth from './ims_auth.js';

/**
 * Initializes global navigation and returns the 'adobeid' configuration object
 * required by IMS.
 *
 * @return {Object}
 */
export async function init() {

  const imsConfig = getData('ims-config');
  if (!imsConfig) {
    return;
  }

  ImsAuth.init(imsConfig.imsOptions, imsConfig.cucumberConfig);

  // The IMSlib library will use this global when it loads
  window.adobeid = ImsAuth.getAdobeId();
}
