module.exports = {
  login: login,
  logout: logout
};

// Use ready state DONE code from the XMLHttpRequest object if it's available.
var READY_STATE_DONE = XMLHttpRequest.DONE || 4; // eslint-disable-line no-magic-numbers
var OK_STATUS = 200;

/**
 * Login to Typekit after being globally logged in with IMS.
 *
 * @param {String} url
 * @param {String} token
 * @param {Function} callback
 */
function login(url, token, callback) {
  _request(url, 'POST', callback, 'access_token=' + token);
}

/**
 * Logout of Typekit before being globally logged out of IMS.
 *
 * @param {String} url
 * @param {Function} callback
 */
function logout(url, callback) {
  _request(url, 'GET', callback);
}

/**
 * Make an XHR call. This method is only meant for logging in/out
 * with IMS. It's not robust and won't handle general purpose XHR tasks.
 *
 * @private
 * @param {String} url
 * @param {String} method
 * @param {Function} callback
 * @param {String} body
 */
function _request(url, method, callback, body) {
  var req = new XMLHttpRequest();
  req.onreadystatechange = function() {
    if (req.readyState == READY_STATE_DONE &&
        req.status == OK_STATUS) {
      if (req.responseText.trim()) {
        callback(JSON.parse(req.responseText));
      } else {
        callback();
      }
    }
  };

  req.open(method, url, true);

  if (method == 'POST') {
    req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  }

  req.setRequestHeader('X-CSRF-Token', _getXsrfToken());
  req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

  req.send(body);
}

/**
 * Gets the CRSF token for XHR requests.
 * @private
 *
 * @return {String}
 */
function _getXsrfToken() {
  var metaTags = document.getElementsByTagName('meta');
  for (var i = 0; i < metaTags.length; i++) {
    if (metaTags[i].getAttribute('name') == 'csrf-token') {
      return metaTags[i].getAttribute('content');
    }
  }
}

