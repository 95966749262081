const authXhr = require('./auth_xhr.js');
const cookie = require('./cookie.js');
const imsEvents = require('./ims_events.js');
const scriptData = require('./script_data.js');
const { getData } = scriptData;
var instance;

/**
 * A wrapper for class for code that deals with IMS authoriziation.
 *
 * @constructor
 * @param {Object} options
 */
function ImsAuth(options, cucumberConfig) {
  this._adobeLanguageTag = options.adobe_language_tag;
  this._clientId = options.clientId;
  this._cucumberConfig = cucumberConfig;
  this._imsEnv = options.imsEnv;
  this._scope = options.scope;
  this._ssoLoginUrl = options.ssoLoginUrl;
  this._ssoLogoutUrl = options.ssoLogoutUrl;
  this._useDebugMode = options.useDebugMode;

  if (options.tkRequestInfo) {
    this._currentRequestMethod = options.tkRequestInfo.requestMethod;
    this._imsCookieName = options.tkRequestInfo.cookieName;
    this._loggedInOnServer = options.tkRequestInfo.loggedInOnServer;
    this._serverAdobeProfileId = options.tkRequestInfo.adobeProfileID;
    this._userHadExpiredAccessToken = options.tkRequestInfo.userHadExpiredAccessToken;
  }

  this._adobeProfilePlaceholder = document.querySelector('.__adobeprofile');
}

module.exports = {
  /**
   * Initializes AdobeIMS
   * @returns {ImsAuth}
   */
  init: function(options, cucumberConfig) {
    // Keep the IMS auth instance as a singleton, because we'll need cached data
    // for things like getCachedProfile.
    if (instance) {
      return;
    }

    instance = new ImsAuth(options, cucumberConfig);
    return instance;
  },

  /**
   * Returns the "adobeid" configuration object that the IMS library will look
   * for when it loads.
   *
   * @returns {Object}
   */
  getAdobeId: function() {
    return instance.getAdobeId();
  },

  getInstance: function() {
    return instance;
  },

  /**
   * Return the cached copy of the user profile that was loaded when IMS
   * initialized.
   */
  getUserProfile: function() {
    if (!instance) {
      return;
    }

    return instance.getCachedProfile();
  },

  getAccessToken: function() {
    if (!instance) {
      return;
    }

    return instance.getAccessToken();
  },

  isSignedInUser: function() {
    if (!instance || !instance.getAdobeIMS()) {
      return false;
    }

    return instance.getAdobeIMS().isSignedInUser();
  }
};

/**
 * Returns the 'adobeId' configuration object for IMS.
 *
 * @return {Object}
 */
ImsAuth.prototype.getAdobeId = function() {
  var self = this;
  return {
    environment: self._imsEnv,

    api_parameters: {
      // On logout, redirect to the homepage.
      //
      // NOTE: By default, the logout button will redirect the user to the
      // same page they were on. This behavior can cause an issue where the third
      // party cookies warning dialog shows up if the user logs into Typekit
      // and then immediate logs out. Redirecting to another page fixes this problem.
      logout: {
        redirect_uri: self._ssoLogoutUrl
      }
    },

    client_id: self._clientId,

    // If this option is true, imslib will forcefully redirect the user
    // to the Sign In workflow on page load
    is_mandatory_sign_in: false,

    // OAuth details
    scope: self._scope,

    use_debug_mode: self._useDebugMode,

    // Redirect users to SUSI (instead of using a modal)
    uses_redirect_mode: true,

    // 1. Fired when the access token is available.
    onAccessToken: function(accessToken) {
      self._accessToken = accessToken.token;
    },

    // 2. Fired when IMS is done initializing.
    onReady: function(state) {
      if (self._cucumberConfig) {
        return self._onReadyForCucumber();
      }

      if (window.adobeIMS.isSignedInUser()) {
        self.getAdobeIMS().getProfile().then(function(profile) {
          self._profile = profile;
          self._onReadyWhenLoggedIn();
        }).finally(function() {
          imsEvents.fireOnReadyHandlers();
        });
      } else {
        self._onReadyWhenNotLoggedIn();
        imsEvents.fireOnReadyHandlers();
      }
    }
  };
};

/**
 * Wrap window.adobeIMS so we don't always have to interact directly with global
 * stored on the window.
 */
ImsAuth.prototype.getAdobeIMS = function() {
  // This global gets set once the adobe IMS library has been initialized.
  return window.adobeIMS;
}

/**
 * The adobeIMS.getProfile() is an async function, which isn't always convenient
 * for older code on our site. To get around this, we cache the profile we
 * obtain when initializing IMS and then have our own wrapper function to
 * return it.
 *
 * @returns {Object}
 */
ImsAuth.prototype.getCachedProfile = function() {
  if (this.getAdobeIMS().isSignedInUser()) {
    return this._profile;
  }
}

ImsAuth.prototype._onReadyForCucumber = function() {
  this._loadInfoFromCucumber();
  this._onReadyWhenLoggedIn();
  imsEvents.fireOnReadyHandlers();
};

/**
 * Handles the IMS onReady event when the user is already logged into IMS.
 * @private
 */
ImsAuth.prototype._onReadyWhenLoggedIn = function() {
  var self = this;

  // If the existing server side Adobe Profile Id doesn't match the ID
  // returned by IMS, log the user in on the server side again.
  if (self._serverAdobeProfileId != (self._profile?.userId || null)) {
    if (self._userHadExpiredAccessToken) {
      // If we logged the user out server-side because they had an expired access token,
      // we need to ensure they are logged out here as well.
      self.getAdobeIMS().signOut({ redirect_uri: window.location.toString() });
    } else {
      authXhr.login(self._ssoLoginUrl + '.json', self._accessToken, function(data) {
        // If the user was logged in successfully on the server and the request method
        // was GET, reload the page to put it in a logged in state.
        if (data.logged_in && self._currentRequestMethod == 'GET') {
          window.location.reload();
        }
      });
    }
  }

  cookie(self._imsCookieName, self._accessToken, {path: "/"});

  if (self._adobeProfilePlaceholder) {
    _initProfileMenu(self._adobeProfilePlaceholder, self._adobeLanguageTag);
  }
};

/**
 * Handles the IMS onReady event when the user is not logged into IMS.
 * @private
 */
ImsAuth.prototype._onReadyWhenNotLoggedIn = function() {
  // Clear the existing IMS cookie if it exists, because it's no longer valid.
  cookie(this._imsCookieName, null);

  if (this._adobeProfilePlaceholder) {
    this._adobeProfilePlaceholder.style.visibility = 'visible';
  }

  // If the user was logged in on the Typekit server side, redirect to the
  // logout url.
  if (this._loggedInOnServer) {
    window.location.href = this._ssoLogoutUrl;
  }
};

/**
 * When this code is running in an integration test, load the profile and access
 * token information from cookies that will be provided by Cucumber.
 * @private
 */
ImsAuth.prototype._loadInfoFromCucumber = function() {
  const self = this;
  const adobeIMS = self.getAdobeIMS();
  self._accessToken = cookie(this._cucumberConfig.imsTokenCookieName);

  if (!self._accessToken) {
    return;
  }

  adobeIMS.isSignedInUser = function() {
    return true;
  };

  adobeIMS.getAccessToken = function() {
    return self._accessToken;
  };

  self._profile = {
    userId: cookie(this._cucumberConfig.userIdCookieName),
    serviceAccounts: JSON.parse(cookie(this._cucumberConfig.serviceAccounts))
  };

  adobeIMS.getProfile = function() {
    return Promise.resolve(this._profile);
  }
};

ImsAuth.prototype.getAccessToken = function() {
  return this._accessToken;
};

/**
 * Initialize the third party "adobeProfile" profile menu that will be displayed
 * in the top right corner of the page.
 */
async function _initProfileMenu(target, locale) {
  const { i18n, urls } = getData('profile-menu-config');
  await _loadProfileMenuAssets(urls.css, urls.js);

  window.adobeProfile.init({
    target,
    locale,
    theme: 'theme-light',
    class: 'adobe-profile-menu', localMenu: {
      title: i18n.account_links,
      links: [
        {
          label: i18n.api_tokens,
          action: '/account/tokens',
          description: i18n.api_tokens
        }
      ]
    }
  });
}

/**
 * Load the assets for the Global Nav Menu.
 *
 * These low priority assets are delay loaded by JS so that they don't block
 * page load.
 */
async function _loadProfileMenuAssets(cssUrl, jsUrl) {
  return Promise.all([
    new Promise(resolve => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = cssUrl;
      style.addEventListener('load', resolve);
      document.head.appendChild(style);
    }),

    new Promise(resolve => {
      const script = document.createElement('script');  
      script.src = jsUrl;
      script.addEventListener('load', resolve);
      document.head.appendChild(script);
    })
  ]);
}
