var _onReadyHandlers = [];
var _onReadyHasFired = false;

module.exports = {
  addOnReadyHandler: addOnReadyHandler,
  fireOnReadyHandlers: fireOnReadyHandlers,
  reset: reset
};

/**
 * Adds a handler function to the list of onReady handlers.
 * @param {Function} func
 */
function addOnReadyHandler(func) {
  if (_onReadyHasFired) {
    func();
  } else {
    _onReadyHandlers.push(func);
  }
}

/**
 * Fires the onReady handlers.
 */
function fireOnReadyHandlers() {
  _onReadyHasFired = true;

  while(_onReadyHandlers.length > 0) {
    _onReadyHandlers.pop()();
  }
}

/**
 * Resets all state.
 */
function reset() {
  _onReadyHandlers = [];
  _onReadyHasFired = false;
}
