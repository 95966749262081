import { loadFonts, init as initDynamicKit } from './DynamicKit.js';
import { init as initIms } from './imslib.js';
import { enableConsentCheck } from './adobe_privacy.js';

export function setupGlobals() {
  initDynamicKit();
  loadFonts();
  initIms();
  enableConsentCheck();
}

